'use client';
import React, { useEffect } from 'react';
import { usePathname } from 'next/navigation';

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

const loadAdsExt = (retry = false) => {
  try {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  } catch (e) {
    console.error(e);
    if (!retry) {
      retryLoadAdsExt();
    }
  }
};

const retryLoadAdsExt = () => {
  setTimeout(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error(e);
    }
  }, 2000);
};

const AdsenseExt = () => {
  const pathname = usePathname();

  useEffect(() => {
    loadAdsExt();
  }, [pathname]);

  return (
    <ins className='adsbygoogle'
      style={{ display: 'block', textAlign: 'center' }}
      data-ad-layout='in-article'
      data-ad-format='fluid'
      data-ad-client='ca-pub-7701478875963146'
      data-ad-slot='3640904400'
      data-full-width-responsive='true'></ins>
  );
};

export default AdsenseExt;
