'use client';
import React, { useEffect } from 'react';
import { usePathname } from 'next/navigation';

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

const loadAds = (retry = false) => {
  try {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  } catch (e) {
    console.error(e);
    if (!retry) {
      retryLoadAds();
    }
  }
};

const retryLoadAds = () => {
  setTimeout(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error(e);
    }
  }, 1000);
};

const Adsense = () => {
  const pathname = usePathname();

  useEffect(() => {
    loadAds();
  }, [pathname]);

  return (
    <ins
      className='adsbygoogle'
      style={{ display: 'block' }}
      data-ad-format='auto'
      data-ad-client='ca-pub-7701478875963146'
      data-ad-slot='8029254369'
      data-full-width-responsive='true'
    ></ins>
  );
};

export default Adsense;